import React from "react";
import "./footer.css";
import jbl from "../../Assets/jbl.svg";
import kenwood from "../../Assets/kenwood.svg";
import bianchi from "../../Assets/bianchi.svg";
import swiggy from "../../Assets/swiggy.svg";
import ln from "../../Assets/ln.svg";
import insta from "../../Assets/insta.svg";
import fb from "../../Assets/fb.svg";
import tw from "../../Assets/tw.svg";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const FooterData = [
    {
      heading: "CONTENT WRITING SERVICES",
      link1: " Installation",
      link2: "Release Notes",
      link3: "Upgrade Guide",
      link4: "Using with Preprocessors",
      link5: "Optimizing for Production",
      link6: "Browser Support",
      link7: " IntelliSense",
    },
  ];
  return (
    <>
      <footer className="bg-[#202020]   pt-[5vw] h-[90vw]  sm:h-[40vw] 2xl:h-auto relative">
        <div className="relative  top-[2vw]">
          <div className="pt-2 sm:block "></div>
          <div className="sm:flex grid grid-cols-2 max-w-6xl m-auto text-gray-800  flex-row justify-evenly">
            <div className="p-5 ">
              <div className="sm:text-[1vw] md:text-[1.2vw] 2xl:text-[1.5vw] aboutUs font-[poppins]  text-white aboutUs font-medium mb-6">
                Address
              </div>

              <div
                href="#"
                className="my-3 block text-gray-400 footer-subheading opacity-80 hover:text-gray-100 text-[0.9vw] font-normal duration-700"
              >
                <div className="text-sm">
                Offiql Technologies,
                <br />
                  Shubhash Road,
                  <br />
                  Dehradun,
                  <br />
                  Uttarakhand (248001)
                </div>
              </div>
            </div>

            <div className="p-5 ">
              <div className="sm:text-[1vw] md:text-[1.2vw] 2xl:text-[1.5vw] aboutUs font-[poppins] aboutUs  text-white font-medium mb-6">
                OFFIQL<span>TECH</span>
              </div>

              <div
                href="#"
                className="my-3 block text-gray-400 footer-subheading opacity-80 hover:text-gray-100 text-[0.9vw] font-normal duration-700"
              >
                <span className="">
                  Transforming businesses with technology.
                </span>
                <div className="mt-[1vw]">business@offiql.com</div>
                <div className="mt-[1vw]">+91 82795 09827</div>
                
              </div>
            </div>
          </div>

          <div className=" flex justify-center items-center flex-col pb-2 text-white w-full">
            <ul className="flex flex-wrap justify-center items-center  policies gap-px font-poppins text-[2px] sm:text-xs">
              <li
                onClick={() => navigate("/terms&condition")}
                className="mx-1 cursor-pointer "
              >
                Terms & Conditions
              </li>
              <li
                onClick={() => navigate("/privacypolicy")}
                className="mx-1 cursor-pointer "
              >
                Privacy Policy
              </li>
              <li
                onClick={() => navigate("/refundpolicy")}
                className="mx-1 cursor-pointer"
              >
                Refund Policy
              </li>
              <li
                onClick={() => navigate("/pricingpolicy")}
                className="mx-1 cursor-pointer "
              >
                Pricing Policy
              </li>
              <li
                onClick={() => navigate("/cancellationpolicy")}
                className="mx-1 cursor-pointer "
              >
                Cancellation Policy
              </li>
            </ul>
            <div className="offiql-register">
              © Offiql Technologies Pvt. Ltd. 2024
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
