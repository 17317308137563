import React from 'react'
import featureGirl from '../../Assets/featureGirl.webp'
import goldenCheck from '../../Assets/goldenCheck.svg'
import './Features.css'
import { Fade } from 'react-awesome-reveal'
const Features = () => {
  const featureArray = [
    {
      message: "Our Active Collaboration and On-Schedule Delivery makes us the Industrie's best with valuable trust."
    },
    {
      message: 'We create Quick solutions for your needs through agile  & customized software development.'
    },
    {
      message: 'Our methodologies offer a higher added value product  to get better results and costs/time optimization in your company processes.'
    },
    {
      message: 'We have an 24*7 On-Support system before and after our Scheduled Deliveries.'
    },
  ]
  return (
    <>
      <div className=' sm:mx-[6vw] mt-[25vw] mb-[6vw] flex flex-row justify-center ' id='services'>
        {/* Textual Content */}
        <div className=' h-[40vw]  sm:w-[45.7vw] bg-[#2F2F2F] features-text '>
          <div className='p-[3.2vw] flex flex-col'>
            <Fade triggerOnce>
              <div className='features-heading text-[2.5vw] font-[poppins] font-light text-[#FAFAFA] feature-heading'>
                Why Clients Love working <br />
                with <span className='text-[#FF8002]'>OffiqlTech ?</span>
              </div>
            </Fade>
            <Fade triggerOnce>
              <div className='mt-[3vw] space-y-[2vw] features'>
                {featureArray.map((value, index) => {
                  return (
                    <FeaturePoints feature={value.message} />
                  )
                })}

              </div>

            </Fade>
          </div>

        </div>

        {/* Photo Content */}
        <div className='relative -left-[3vw]'>
          <img src={featureGirl} className="h-[40vw] w-[45.7vw] hidden sm:block" />
        </div>
      </div>

    </>
  )
}

export default Features

const FeaturePoints = (props) => {
  return (
    <>
      <div className='flex flex-row space-x-[1vw]'>
        <div> <img src={goldenCheck} className="relative top-[0.5vw] " /></div>
        <div className='max-w-[32vw] text-[#888888] text-[1.1vw] feature-text'>{props.feature}</div>
      </div>
    </>
  )
}