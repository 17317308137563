import Footer from "./Components/Footer/Footer";
import { Routes, Route, Link } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Portfolio from "./Pages/Portfolio/Portfolio";
import { useState } from "react";
import PortfolioCompanyComponent from "./Components/Portfolio Component/PortfolioCompanyComponent";
import IMAGES from './Assets/portfolio/Images'

import {
  fello_2, fello_3, fello_4, fello_5, fello_6,
  fello_hero, desmantle_2, desmantle_3, desmantle_4, desmantle_5,
  desmantle_1, zing_2, zing_3, zing_4, zing_5, zing_1,
  uden_2, uden_3, uden_4, uden_5, uden_1,
  od_2, od_3, od_4, od_5, od_1,
  coin2, coin3, coin4, coin1,
} from './Assets/portfolio/Images'
import maya1 from '../src/Assets/portfolio/mayadaar/mayadaar1.webp'
import maya2 from '../src/Assets/portfolio/mayadaar/mayadaar2.webp'
import maya3 from '../src/Assets/portfolio/mayadaar/mayadaar3.webp'
import maya4 from '../src/Assets/portfolio/mayadaar/mayadaar4.webp'
import maya5 from '../src/Assets/portfolio/mayadaar/mayadaar5.webp'
import maya6 from '../src/Assets/portfolio/mayadaar/mayadaar6.webp'
import maya7 from '../src/Assets/portfolio/mayadaar/mayadaar7.webp'
import maya8 from '../src/Assets/portfolio/mayadaar/mayadaar8.webp'

import maangal1 from '../src/Assets/portfolio/maangal/maangal1.webp'
import maangal2 from '../src/Assets/portfolio/maangal/maangal2.webp'
import maangal3 from '../src/Assets/portfolio/maangal/maangal3.webp'
import maangal5 from '../src/Assets/portfolio/maangal/maangal5.webp'
import maangal6 from '../src/Assets/portfolio/maangal/maangal6.webp'
import maangal7 from '../src/Assets/portfolio/maangal/maangal7.webp'

import WebFooter from "./Components/Web Footer/WebFooter";
import offiql1 from '../src/Assets/portfolio/offiql/offiql1.png'
import offiql2 from '../src/Assets/portfolio/offiql/offiql2.jpeg'
import offiql3 from '../src/Assets/portfolio/offiql/offiql3.png'
import offiql4 from '../src/Assets/portfolio/offiql/offiql4.png'
import clp1 from './Assets/portfolio/clapingo/clp1.png'
import clp2 from './Assets/portfolio/clapingo/clp2.png'
import clp3 from './Assets/portfolio/clapingo/clp3.png'
import clp4 from './Assets/portfolio/clapingo/clp4.png'
import em1 from './Assets/portfolio/eventmender/em1.png'
import em2 from './Assets/portfolio/eventmender/em2.png'
import em3 from './Assets/portfolio/eventmender/em3.png'
import em4 from './Assets/portfolio/eventmender/em4.png'
import gg1 from './Assets/portfolio/ggnation/gg1.png'
import gg2 from './Assets/portfolio/ggnation/gg2.png'
import gg3 from './Assets/portfolio/ggnation/gg3.png'
import gg4 from './Assets/portfolio/ggnation/gg4.png'
import { TermsCondition } from "./Components/policies/termsCondition";
import { PrivacyPolicy } from "./Components/policies/privacyPolicy";
import { CancellationPolicy } from "./Components/policies/cancellationPolicy";
import { RefundPolicy } from "./Components/policies/refundPolicy";
import { PricingPolicy } from "./Components/policies/pricingPolicy";




function App() {
  const [images, setImages] = useState([])
  const portfolioImages = (image1, image2, image3, image4, image5, image6, image7) => {
    setImages([image1, image2, image3, image4, image5, image6, image7])

  }
  console.log(images.length)
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  return (
    <>
      <Routes>

        <Route path="/" element={<Home portfolioImages={portfolioImages} />} />
        <Route path="/Home" element={<Home portfolioImages={portfolioImages} />} />

        <Route path="/portfolio"
          element={<Portfolio />}>

        </Route>
        
        <Route path="/portfolio/fello" element={<PortfolioCompanyComponent image1={fello_hero} image2={fello_2} image3={fello_3} image4={fello_4} image5={fello_5} image6={fello_6} />} />
        <Route path="/portfolio/desmantle" element={<PortfolioCompanyComponent heading='desmantle' image1={desmantle_1} image2={desmantle_2} image3={desmantle_3} image4={desmantle_4} image5={desmantle_5} />} />
        
        <Route path="/portfolio/zing" element={<PortfolioCompanyComponent heading='zing' image1={zing_1} image2={zing_2} image3={zing_3} image4={zing_4} image5={zing_5} />} />
        <Route path="/portfolio/uden" element={<PortfolioCompanyComponent heading='uden' image1={uden_1} image2={uden_2} image3={uden_3} image4={uden_4} image5={uden_5} />} />
        
        <Route path="/portfolio/OD_Works" element={<PortfolioCompanyComponent heading='od' image1={od_1} image2={od_2} image3={od_3} image4={od_4} image5={od_5} />} />
        <Route path="/portfolio/killshiba" element={<PortfolioCompanyComponent heading='killshiba' image1={coin1} image2={coin2}  image4={coin4} />} />
        
        <Route path="/portfolio/sportycoon" element={<PortfolioCompanyComponent heading='sportycoon' />} />
        <Route path="/portfolio/mayadaar" element={<PortfolioCompanyComponent heading='Mayadaar' image1={maya1} image2={maya2} image3={maya3} image4={maya4} image5={maya5} image6={maya6} image7={maya7} image8={maya8} grid={true}/>} />
        
        <Route path="/portfolio/maangal" element={<PortfolioCompanyComponent heading='Maangal' image1={maangal1} image2={maangal3} image3={maangal2} image4={maangal5}  image7={maangal6} image8={maangal7} grid={true} />} />
        <Route path="/portfolio/eventmender" element={<PortfolioCompanyComponent heading='Eventmender' image1={em1} image2={em2} image3={em3} image4={em4} />} />
        
        <Route path="/portfolio/ggnation" element={<PortfolioCompanyComponent heading='GoodGame Nation' image1={gg1} image2={gg2} image3={gg3} image4={gg4} />} />
        <Route path="/portfolio/clapingo" element={<PortfolioCompanyComponent heading='Clapingo' image1={clp1} image2={clp2} image3={clp3} image4={clp4} />} />
        
        <Route path="/portfolio/offiql" element={<PortfolioCompanyComponent heading='Offiql Technologies' image1={offiql3} image2={offiql1} image3={offiql4} image4={offiql2} />} />
        <Route path="/terms&condition" element={<TermsCondition/>}/>
        <Route path="/privacypolicy" element={<PrivacyPolicy/>}/>
        <Route path="/refundpolicy" element={<RefundPolicy/>}/>
        <Route path="/pricingpolicy" element={<PricingPolicy/>}/>
        <Route path="/cancellationpolicy" element={<CancellationPolicy/>}/>
      </Routes>


      {getWindowDimensions().width <= 640 ? <Footer /> : <WebFooter />}
    </>
  );
}

export default App;
