import React, { useEffect, useContext } from 'react'
import happyGirl from '../../Assets/modal Images/happyGirl.webp'
import './Questionaire.css'
import { InlineWidget } from "react-calendly";
import chevronDown from '../../Assets/chevronDown.svg'
import { useState } from 'react';
import { Context } from '../../Store/Store'
import axios from 'axios';

const Questionaire = ({ setModalState, questionaireData, setIncrementValue }) => {

    const [checkForSubmit, setCheckForSubmit] = useState(0)
    const [dropdown1, setDropdown1] = useState(false);
    const [dropdown2, setDropdown2] = useState(false);
    const [dropdown3, setDropdown3] = useState(false);
    const [data, setData] = useState({
        "email": "",
        "firstName": "",
        "phoneNumber": "",
        "requirement": "",
        "time": "",
        "referralSource": ""
    })


    const [email, setEmail] = useState('')
    const [state] = useContext(Context)
    const dropDownValues = (value1, value2, value3) => {
        setDropdown1(value1);
        setDropdown2(value2);
        setDropdown3(value3);
    }
    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height,
        };
    }
    async function sendQuoteEmail() {
        console.log(checkForSubmit)
        if (checkForSubmit === 1) {
            try {
                const email = localStorage.getItem("email")
                const firstName = localStorage.getItem("firstName")
                const phoneNumber = localStorage.getItem("phoneNumber")
                const requirement = localStorage.getItem('requirement')
                const time = localStorage.getItem("time")
                const referralSource = localStorage.getItem('referralSource')



                await axios.post("https://eventapi.ctoninja.tech/sendQuote",
                    {
                        email: email,
                        firstName: firstName,
                        phoneNumber: phoneNumber,
                        requirement: requirement,
                        time: time,
                        referralSource: referralSource
                    }
                )
         

            } catch (error) {
                console.log(error)
            }

        } else {
            setCheckForSubmit((prev) => prev + 1)
        }

    }


    return (
        <>
            <div
                onClick={() => {
                    setModalState(false);
                    setIncrementValue(0)

                }}
                className='overflow-hidden overflow-y-auto sm:overflow-hidden grid place-content-center modal-z-index bg-black bg-opacity-50 fixed p-10 top-0 left-0 right-0 bottom-0 h-screen  w-screen'>
                <div className='w-[2vw] absolute top-[2vw] cross-position'
                    onClick={() => {
                        setModalState(false);

                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="white" viewBox="0 0 24 24" stroke="#fff" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </div>
                <div
                    onClick={(e) => {
                        e.stopPropagation();
                        if (dropdown1) {
                            setDropdown1(false)
                        } else if (dropdown2) {
                            setDropdown2(false);

                        } else if (dropdown3) {
                            setDropdown3(false)
                        }
                    }}
                    className='mx-[6vw] z-50 mt-[5vw] mb-[6vw] flex sm:flex-row flex-col justify-center'>

                    <div className='sm:h-[40vw] sm:w-[45.7vw] h-[125vw] w-[90vw] bg-[#2F2F2F] relative sm:-right-[2.5vw] '>
                        {/* Textual Content */}
                        <div className='p-[3.2vw] flex flex-col mt-[5vw] sm:mt-0'>
                            {questionaireData.calendlyLink ? <></> : <div className='text-[6vw] sm:text-[1.8vw] font-[poppins] font-light text-[#f9c240]'>
                                {questionaireData.title}
                            </div>}
                            <div>
                                {questionaireData.question1 === 'For us to reach out, please share your Email id' ?
                                    <>
                                        <QuestionBox question={questionaireData.question1} type='text' placeholder={questionaireData.question1 === 'For us to reach out, please share your Email id' ? 'johndoe@example.com' : 'Ex-Instagram'} name={"email"} />
                                        <QuestionBox question={questionaireData.question2} type='text' placeholder={questionaireData.question2 === "What's your first name" ? 'John' : '1-3 months'} name={"firstName"} />
                                        <QuestionBox question={questionaireData.question3} type={questionaireData.question3 === 'Please share your phone number' ? 'number' : 'text'} placeholder='+91' name={"phoneNumber"} />
                                    </>

                                    : questionaireData.question1 == 'Tell us, what are your requirements' ?
                                        <>
                                            <DropDownButton question={questionaireData.question1} placeholder='Hire UI/UX designer' dropdownContent={questionaireData.dropdownContent1} dropDownValues={dropDownValues} isModal1Open={dropdown1} />
                                            <DropDownButton question={questionaireData.question2} placeholder='1-3 months' dropdownContent={questionaireData.dropdownContent2} dropDownValues={dropDownValues} isModal2Open={dropdown2} />
                                            <DropDownButton question={questionaireData.question3} placeholder='Twitter' dropdownContent={questionaireData.dropdownContent3} dropDownValues={dropDownValues} isModal3Open={dropdown3} />
                                        </>

                                        : questionaireData.successMessage ?
                                            <div className='text-white opacity-80 text-[4vw] sm:text-[0.9vw] mt-[6vw] sm:mt-[3vw] sm:max-w-[30vw]'>
                                                {questionaireData.successMessage}
                                            </div>

                                            : questionaireData.calendlyLink ?
                                                <div className='text-[1.8vw] font-[poppins] font-medium  text-[#f9c240] grid place-content-center h-[30vw]'>
                                                    <div>
                                                        You're just a step ahead ,please schedule a meeting with our experts
                                                    </div>
                                                </div>

                                                : ''

                                }
                                {/* for continue button */}
                                {/* for continue button */}


                                <div

                                    onClick={() => {
                                        setIncrementValue(1)
                                        sendQuoteEmail()
                                    }}
                                    className={'rounded-[1.5vw] sm:rounded-[0.3vw] grid place-content-center w-[40vw] sm:w-[10vw] bg-[#f9c240] mt-[6vw] sm:mt-[3vw] cursor-pointer'}>
                                    <button type="button" className='px-[5vw] sm:px-[1vw] py-[3vw] sm:py-[0.4vw] text-[4vw] sm:text-[0.8vw] text-black font-[poppins] font-medium'>
                                        Continue
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>

                    {/* Photo Content */}
                    <div className='block '>
                        {questionaireData.calendlyLink ?
                            <>
                                <div className='bg-white calendly-link sm:relative h-[100vw] sm:h-[40vw]  sm:w-[43vw]'>
                                    <InlineWidget url="https://calendly.com/pulkitmittalbusiness" styles={getWindowDimensions().width <= 640 ? { height: '100vw', width: '80vw' } : { height: '40vw', width: '45.7vw' }} />
                                </div>


                            </> :

                            <>
                                {/* <ProgressiveImg
                                    src={happyGirl}
                                    placeholderSrc={happyGirl2}
                                    width="45.7vw"
                                    height="40vw"
                                /> */}
                                <img src={happyGirl} className="h-[40vw] w-[45.7vw] sm:block hidden" />
                            </>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Questionaire

const QuestionBox = (props) => {

    function handleData(event) {
        localStorage.setItem(`${props.name}`, event.target.value)
    }
    return (
        <>
            <div className='flex flex-col mt-[3vw]'>
                <div className='sm:text-[0.8vw] text-[4vw] text-[white]'>
                    {props.question}
                </div>
                <div className='question-input mt-[5vw] sm:mt-[1vw]'>

                    <input
                        type={props.type}
                        className='w-[80vw] h-[10vw] sm:w-[20vw] sm:h-[1vw] relative border-[0.1vw] '
                        placeholder={props.placeholder}
                        name={props.name}
                        required={props.placeholder === 'johndoe@example.com' ? true : false}
                        onChange={(e) => handleData(e)}
                    />

                </div>

            </div>

        </>
    )
}


const DropDownButton = ({ question, placeholder, dropdownContent, dropDownValues, isModal1Open, isModal2Open, isModal3Open }) => {
    const [question1, setQuestion1] = useState(false)
    const [question2, setQuestion2] = useState(false)
    const [question3, setQuestion3] = useState(false)
    useEffect(() => {
        if (question === 'Tell us, what are your requirements') {
            setQuestion1(true)
        } else if (question === 'Approximately , how long do you expect to be working with them ?') {
            setQuestion2(true)
        } else if (question === 'Where did you hear about us ?') {
            setQuestion3(true)
        }
        // question === 'Tell us, what are your requirements' ? setQuestion1(true) : question === 'Approximately , how long do you expect to be working with them ?' ? setQuestion2(true) : question === 'Where did you hear about us ?' ? setQuestion3(true): '' 
    }, [])

    const [dropdown1, setDropdown1] = useState(false);
    const [dropdown2, setDropdown2] = useState(false);
    const [dropdown3, setDropdown3] = useState(false);
    const [isOpen, setIsOpen] = useState(false)
    const [dropdownContent1, setDropdownContent1] = useState()
    console.log(question1)
    return (
        <>

            <div className='flex flex-col mt-[3vw] question-outer-div relative'>
                <div onClick={() => {

                    setIsOpen(!isOpen)
                    if (question1) {

                        dropDownValues(true, false, false)

                    } else if (question2) {
                        dropDownValues(false, true, false)

                    } else if (question3) {
                        dropDownValues(false, false, true)

                    }
                }} >
                    <div className=' text-[white] sm:text-[0.8vw] text-[4vw] '>
                        {question}
                    </div>
                    <div className='dropdown-button   text-left font-[poppins] relative'>
                        <div className='dropdown-placeholder '>
                            {dropdownContent1 ? dropdownContent1 : `${placeholder}`}
                        </div>
                        <div className='absolute chevronDown'>
                            <img src={chevronDown} className="w-[5vw] h-[5vw]" />
                        </div>
                    </div>
                </div>

                {isModal1Open || isModal2Open || isModal3Open ?
                    <div className='dropdown-menu'>
                        {dropdownContent.map((val, index) => {
                            return (
                                <div className='p-[4vw] sm:p-[0.6vw] bg-transparent cursor-pointer hover:bg-gray-300 text-[4vw] sm:text-[0.8vw]'
                                    onClick={() => {
                                        setDropdownContent1(val);
                                        setIsOpen(false)
                                        if (question1) {
                                            dropDownValues(false, false, false)
                                            setDropdown1(false);
                                            localStorage.setItem('requirement', val)

                                        } else if (question2) {
                                            setDropdown2(false);
                                            dropDownValues(false, false, false)
                                            localStorage.setItem('time', val)

                                        } else if (question3) {
                                            setDropdown3(false);
                                            dropDownValues(false, false, false)
                                            localStorage.setItem('referralSource', val)
                                        }
                                    }}
                                >
                                    {val}
                                </div>
                            )
                        })}

                    </div>
                    : <></>
                }
            </div>
        </>
    )
}

const progressiveImage = ({ placeholderSrc, src, ...props }) => {
    return (
        <img
            {...{ src: placeholderSrc, ...props }}
            alt={props.alt || ""}
            className="image"
        />
    )
}