import React from "react";
import "./WebFooter.css";
import ln from "../../Assets/ln.svg";
import insta from "../../Assets/insta.svg";
import fb from "../../Assets/fb.svg";
import tw from "../../Assets/tw.svg";
import { useNavigate } from "react-router-dom";

const WebFooter = () => {
  const navigate=useNavigate()
  return (
    <>
      <div className="footer-web ">
        <div className="footer-web-outer-div">
          <div className="offiql-heading  ">
            <div onClick={()=>navigate("/")} className="cursor-pointer offiql-name">
              OFFIQL<span className="text-[#FF8003]">TECH</span>
            </div>
            <div className="offiql-subheading">
              Transforming businesses with technology.
            </div>
          </div>

          <div className="offiql-info ">
            <div className="offiql-address-info">
              <div className="registered-company">
                Offiql Technologies Pvt. Ltd
              </div>
              <div className="address-line">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="svg-height h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                    clip-rule="evenodd"
                    fill="#ffff"
                  />
                </svg>
                <div className="address">
                  Shubhash Road, Dehradun,
                  <br />
                  Uttarakhand 248001
                </div>
              </div>
              <div className="address-line">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="svg-height-2 h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    fill="#ffff"
                  />
                </svg>
                <div className="address-1"> business@offiql.com</div>
              </div>
              <div className="address-line">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="svg-height-2 h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"
                    fill="#ffff"
                  />
                </svg>
                <div className="address-1">+91 82795 09827</div>
              </div>
            </div>
          </div>
        </div>
        <div className=" flex justify-center items-center flex-col pb-2 text-white w-full">
          <ul className="flex policies gap-1.5 font-poppins text-[2px] sm:text-xs">
            <li onClick={()=>navigate("/terms&condition")} className="pr-1 cursor-pointer border-r-2   border-white">Terms & Conditions</li>
            <li  onClick={()=>navigate("/privacypolicy")}  className="pr-1 cursor-pointer border-r-2 border-white">Privacy Policy</li>
            <li  onClick={()=>navigate("/refundpolicy")} className="pr-1 cursor-pointer border-r-2 border-white">Refund Policy</li>
            <li  onClick={()=>navigate("/pricingpolicy")} className="pr-1 cursor-pointer border-r-2 border-white">Pricing Policy</li>
            <li  onClick={()=>navigate("/cancellationpolicy")} className="pr-1 cursor-pointer ">Cancellation Policy</li>
          </ul>
          <div className="offiql-register">
            © Offiql Technologies Pvt. Ltd. 2024
          </div>
        </div>
      </div>
    </>
  );
};

export default WebFooter;
