import React, { useState } from 'react'
import ServicesCard from '../Services Card/ServicesCard'
import icon1 from '../../Assets/icon1.svg'
import icon2 from '../../Assets/icon2.svg'
import icon3 from '../../Assets/icon3.svg'
import icon4 from '../../Assets/icon4.svg'
import icon5 from '../../Assets/icon5.svg'
import Questionaire from '../Questionaire/Questionaire'
import './servicesSection.css'



const ServicesSection = () => {


    const [isOpen, setOpen] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const setModalState = (value) => {
        setIsModalOpen(value)
    }
    const [increment, setIncrement] = useState(0)
    const [isButtonActive, setIsButtonActive] = useState(false)
    const setIncrementValue = (value) => {
        setIncrement(increment + value)

    }
const openModal = (value) => {
    setIsModalOpen(value)
}
const questionaireData = [
    {
        title: "Let's get you started quickly",
        question1: 'For us to reach out, please share your Email id',
        question2: "What's your first name",
        question3: "Please share your phone number"

    },
    {
        title: "Perfect, just a few things left !",
        question1: "Tell us, what are your requirements",
        question2: "Approximately , how long do you expect to be working with them ?",
        question3: "Where did you hear about us ?",
        dropdownContent1: ['Product Designer', 'Web Developer ', 'UI/UX designer', 'App developer', 'Graphic designer'],
        dropdownContent2: ['1-3 months ', '3-6 months', '6-9 months', '9-12 months'],
        dropdownContent3: ['Instagram', 'Twitter', 'Facebook', 'Friends', 'others'],
    },
    {
        title: "",
        calendlyLink: 'https://calendly.com/pulkitmittalbusiness'

    },
    {
        title: "Wohoo ! We've received your talent requirements.",
        successMessage: 'Within the next 24-48 hours, you will hear from our Talent Matching expert who will sharepersonalized talent recommendations for your project.',

    },

]
    const servicesData = [
        {
            title: 'Product Management',
            subtitle: '20+ plus running projects | 3 teams available ',
            image: icon1
        },
        {
            title: 'Web  Development',
            subtitle: '20+ plus running projects | 3 teams available ',
            image: icon2
        },
        {
            title: 'Mobile Development',
            subtitle: '20+ plus running projects | 3 teams available ',
            image: icon3
        },
        {
            title: 'Graphic Design & Creatives ',
            subtitle: '20+ plus running projects | 3 teams available ',
            image: icon4
        },
        {
            title: 'Hire Product Designer',
            subtitle: '20+ plus running projects | 3 teams available ',
            image: icon5
        },
        {
            title: 'Hire Ui/Ux Designers',
            subtitle: '20+ plus running projects | 3 teams available ',
            image: icon5
        },
        {
            title: 'Hire App Developers',
            subtitle: '20+ plus running projects | 3 teams available ',
            image: icon5
        },
        {
            title: 'Hire Web Developers',
            subtitle: '20+ plus running projects | 3 teams available ',
            image: icon5
        },
    ]
    return (
        <>
            <div id='services' className='h-[190vw] services-section sm:h-[65vw] lg:h-[55vw] w-full bg-black flex flex-col justify-center  text-center'>
                <div className='relative -top-[3vw]'>
                    <div className='font-[poppins] text-[5vw] sm:text-[3vw] text-[#FF8002] font-normal  mb-[1.5vw]'>Our Services</div>
                    <div className='text-white  text-[3.5vw] sm:text-[1.6vw] font-[poppins]'>
                        Save time managing your business <br />
                        with our best services
                    </div>
                </div>
                <div 
                onClick={()=>setIsModalOpen(true)}
                className='grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 relative left-[4vw] sm:left-0  mx-[4vw] sm:mx-[18vw] lg:mx-[4vw] lg:left-[0.5vw] gap-y-[4vw] sm:gap-y-[2vw]'>
                    {servicesData.map((value, index) => {
                        return (
                            <ServicesCard
                                image={value.image}
                                title={value.title}
                                subtitle={value.subtitle}
                                

                            />
                        )
                    })}

                </div>

            </div>
            {isModalOpen && increment < 4 ?
                <Questionaire setModalState={setModalState} questionaireData={questionaireData[increment]} setIncrementValue={setIncrementValue} />
                : <></>
            }
        </>
    )
}

export default ServicesSection