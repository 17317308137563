import React from 'react'
import clock from '../../Assets/clock.svg'
import camera from '../../Assets/cameraIcon.svg'
import './Contact.css'
import { InlineWidget } from "react-calendly";
import { PopupWidget } from "react-calendly";
import { useState } from 'react';
import axios from 'axios';


const Contact = () => {
    const [data, setData] = useState({
        fullName: '',
        contact: '',
        email: '',
        requirements: '',
        message: ''
    })
    const [emailSent, setEmailSent] = useState(false)
    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height,
        };
    }


    return (
        <>

            <div className='w-full h-[180vw] sm:h-[70vw] bg-[#444444]  ' id='contact'>
                <div className=' text-[6vw] sm:text-[3vw] text-[poppins] text-[#F07A06] grid place-content-center relative top-[2vw]'>
                    <div>

                        Get In Touch
                    </div>
                </div>
                <div className='mx-[6vw] my-[6vw] flex flex-col sm:flex-row justify-center items-center'>
                    <div className='w-[80vw] h-[80vw] sm:h-[45vw] sm:w-[43.7vw] bg-white relative left-[2vw]'>
                        {/* Textual Content */}
                        <div className=' flex flex-col p-[3vw] justify-center'>

                            <div className='sm:block relative '>

                                {
                                    getWindowDimensions().width <= 640 ?
                                        <>
                                            <InlineWidget url="https://calendly.com/pulkitmittalbusiness" styles={{
                                                width: '75vw',
                                                height: '70vw',
                                                position: 'relative'
                                            }} />
                                        </>
                                        :
                                        <>
                                            <InlineWidget url="https://calendly.com/pulkitmittalbusiness" styles={{
                                                width: '37vw',
                                                height: '40vw',
                                                position: 'relative'
                                            }} />
                                        </>

                                }
                            </div>
                        </div>

                    </div>

                    {/* Contact Fields */}
                    <div className='relative left-[2vw] sm:-left-[1vw] w-[80vw]  sm:h-[45vw] sm:w-[40.7vw] bg-gray-300'>
                        <div className=' flex flex-col px-[7vw] py-[5.5vw] '>
                            <div className='font-[poppins] font-semibold text-black text-[3vw] sm:text-[1.5vw] flex flex-col'>
                                <span>Get In Touch With Us.</span>
                                <span className='text-[2vw] sm:text-[0.8vw] font-medium text-gray-500'>Our team will contact you within 24-48 hours.</span>
                            </div>
                            <form
                               name="contact" data-netlify="true" netlify-honeypot="bot-field" method='post'
                                onSubmit={"submit"}
                                
                            >
                                <input type="hidden" name="form-name" value="contact" />
                                <div className='grid grid-cols-2 mt-[4vw] gap-x-[4vw] gap-y-[2vw]'>

                                    <div className='contact flex flex-col '>
                                        <label className='text-black text-[2vw] sm:text-[0.8vw] font-[poppins] font-medium '>Full Name</label>
                                        <input
                                            type='text'
                                            placeholder='John Doe'
                                            className='w-[30vw] sm:w-[14vw] mt-[0.5vw]'
                                            name='name'
                                            onChange={(e) => setData({ ...data, fullName: e.target.value })}
                                        />
                                    </div>
                                    <div className='contact flex flex-col'>
                                        <label className='text-black text-[2vw] sm:text-[0.8vw] font-[poppins] font-medium '>Contact</label>
                                        <input
                                            type='text'
                                            placeholder='+91 56789876'
                                            className='w-[30vw] sm:w-[14vw] mt-[0.5vw]'
                                            name='contact'
                                            onChange={(e) => setData({ ...data, contact: e.target.value })}

                                        />
                                    </div>
                                    <div className='contact flex flex-col'>
                                        <label className='text-black text-[2vw] sm:text-[0.8vw] font-[poppins] font-medium '>Email Address</label>
                                        <input
                                            type='email'
                                            placeholder='John Doe'
                                            className='w-[30vw] sm:w-[14vw] mt-[0.5vw]'
                                            name =' email'
                                            onChange={(e) => setData({ ...data, email: e.target.value })}

                                        />
                                    </div>
                                    <div className='contact flex flex-col'>
                                        <label className='text-black text-[2vw] sm:text-[0.8vw] font-[poppins] font-medium '>Requirements</label>
                                        <input
                                            type='text'
                                            placeholder='Web Development'
                                            className='w-[30vw] sm:w-[14vw] mt-[0.5vw]'
                                            name='requirements'
                                            onChange={(e) => setData({ ...data, requirements: e.target.value })}

                                        />
                                    </div>
                                    <div className='contact'>
                                        <label className='text-black text-[2vw] sm:text-[0.8vw] font-[poppins] font-medium '>Message</label>
                                        <textarea
                                            type='text'
                                            placeholder=''
                                            className='w-[65vw] sm:w-[29.5vw] h-[20vw] sm:h-[10vw] mt-[0.5vw] rounded-[0.4vw]'
                                            name = 'message'
                                            onChange={(e) => setData({ ...data, message: e.target.value })}

                                        />
                                    </div>


                                </div>
                                <div
                                 
                                    className=' cursor-pointer mt-[2vw] sm:mt-[0.5vw] grid place-content-center w-[20vw] sm:w-[12vw] sm:h-[3vw] h-[6vw] bg-black rounded-[0.3vw]'>
                                    <button type='submit' className='text-white font-[poppins] text-[2vw] sm:text-[1.2vw] '>Get in touch</button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact