import React from 'react'
import offiql from '../../Assets/portfolio/offiql.svg'
import eventmender from '../../Assets/portfolio/eventMender.PNG'
import clapingo from '../../Assets/portfolio/clapingo.svg'
import ggnation from '../../Assets/portfolio/ggnation.PNG'
import './CaseStudy.css'
import { Fade } from "react-awesome-reveal";
import { Link } from 'react-router-dom'


const CaseStudy = () => {
    return (
        <>
            <div className=' relative w-full h-auto overflow-hidden' id='portfolio'>
                {/* right Blob */}
                <div className='w-[35vw] h-[35vw] bg-[#FF8002] absolute -right-[14vw]  rounded-full z-10' ></div>
                <div className='p-[2vw]'>
                    <div className=' flex flex-col font-[poppins] font-semibold text-[6vw] sm:text-[3vw]'>
                        <div> Our <span className='text-[#FF8002]'>Portfolio</span></div>
                        <div className='max-w-[40vw] sm:max-w-[35vw] text-[2vw] sm:text-[1.2vw] font-normal opacity-80 text-gray-700 mt-[2vw]'>Have a look on some of our fine works and services provided to our happy client all around the world.</div>
                    </div>

                    <div className='flex flex-row justify-evenly mt-[8vw] ml-[4vw] sm:ml-0'>
                        <div className='flex flex-col font-[poppins]'>
                            <Fade triggerOnce>
                                <div className='flex flex-col mb-[5vw]'>
                                    <a href="https://offiql.com/" target={"_blank"}>

                                        <img src={offiql} className='w-[35.5vw] h-[] mb-[2vw] img-box-shadow' alt="case1" />
                                    </a>
                                    <div className='text-black company-name text-[4vw] sm:text-[1.3vw] max-w-[40vw] sm:max-w-0  font-medium mb-[1vw]'>
                                        OFFIQL Advertising Technologies
                                    </div>
                                    <div className=' hidden sm:flex   sm:flex-row  sm:space-x-[2vw]   portfolio-tags'>
                                        <Tags tag="Web Development" />
                                        <Tags tag="App Development" />
                                        <Tags tag="UI/UX" />

                                    </div>
                                </div>
                            </Fade>
                            <Fade triggerOnce>
                                <div className='flex flex-col'>
                                    <a href="https://platform.eventmender.com/" target={"_blank"}>
                                        <img src={eventmender} className='w-[35.5vw] h-[] mb-[2vw] img-box-shadow' alt="case2" />
                                    </a>
                                    <div className='text-black company-name text-[4vw] sm:text-[1.3vw] max-w-[40vw] sm:max-w-0  font-medium mb-[1vw]'>
                                        Platform Eventmender
                                    </div>
                                    <div className='hidden sm:flex   sm:flex-row  sm:space-x-[2vw]'>
                                        <Tags tag="Web Development" />

                                        <Tags tag="UI/UX" />

                                    </div>
                                </div>
                            </Fade>

                        </div>
                        <div className='flex flex-col font-[poppins] relative -top-[15vw] z-50'>
                            <Fade triggerOnce>
                                <div className='flex flex-col mb-[5vw]'>
                                    <a href="https://clapingo.com/" target={"_blank"}>
                                        <img src={clapingo} className='w-[35.5vw] h-[] mb-[2vw] img-box-shadow' alt="case3" />
                                    </a>
                                    <div className='text-black company-name text-[4vw] max-w-[40vw] sm:text-[1.3vw] sm:max-w-0  font-medium mb-[1vw]'>
                                        Clapingo
                                    </div>
                                    <div className='hidden sm:flex  sm:flex-row  sm:space-x-[2vw]'>
                                        <Tags tag="Web Development" />
                                        <Tags tag="App Development" />
                                        <Tags tag="UI/UX" />

                                    </div>
                                </div>
                            </Fade>
                            <Fade triggerOnce>
                                <div className='flex flex-col mb-[5vw]'>
                                    <a href='https://ggnation.com/' target={"_blank"}>

                                        <img src={ggnation} className='w-[35.5vw] h-[] mb-[2vw] img-box-shadow' alt="case4" />
                                    </a>
                                    <div className='text-black text-[4vw]  sm:text-[1.3vw] font-medium mb-[1vw] company-name'>
                                        GoodGame (GG) Nation
                                    </div>
                                    <div className='hidden sm:flex  sm:flex-row  sm:space-x-[2vw]'>
                                        <Tags tag="Web Development" />
                                        <Tags tag="UI/UX" />

                                    </div>
                                </div>
                            </Fade>

                        </div>

                    </div>
                    <Link to="/portfolio" >

                        <div className='w-full flex justify-center font-[poppins] mt-[4vw]'>
                            <div className=' w-[60vw] sm:w-[21.5vw] '>
                                <div className=' text-[3.3vw] cursor-pointer flex-row sm:p-[1vw] p-[4vw] font-medium sm:text-[1vw] bg-[#FF8002] flex justify-center items-center rounded-[2vw] sm:rounded-[0.3vw] space-x-[1vw]'>
                                    <span>
                                        Explore more Projects
                                    </span>

                                    <span className=''>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-[1.2vw] h-[1.2vw]" viewBox="0 0 20 20" fill="currentColor">
                                            <path fill-rule="evenodd" d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                            <path fill-rule="evenodd" d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </>
    )
}

export default CaseStudy

const Tags = (props) => {
    return (
        <>
            <div className='shadow-md sm:shadow-lg w-auto h-[2vw] text-[0.8vw] text-black rounded-[0.3vw] tag-card '>
                <span className='p-[1vw] '>{props.tag}</span>
            </div >
        </>
    )
}