import { useEffect } from "react";

export function RefundPolicy() {
  useEffect(() => {
    const element = document.getElementById("refundPolicy");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  return (
    <div id="refundPolicy" className="min-h-screen w-screen">
      <header class="bg-black text-white text-center py-4">
        <h1 class="text-3xl font-bold">
          <strong>Refund Policy</strong>
        </h1>
        <p>
          Welcome to{" "}
          <a href="https://offiqltech.com/" target="_blank" class="underline">
            www.offiqltech.com
          </a>
        </p>
      </header>

      <section class="container max-w-4xl mx-auto flex flex-col gap-7 my-8 px-3">
        <div class="prose flex flex-col gap-4 max-w-full">
          <h2 className="text-2xl ">
            <strong>No Refund</strong>
          </h2>
          <p>
            Due to the nature of the SERVICES OR PRODUCTS all the SERVICES OR
            PRODUCTS offered through the website are non-refundable under any
            circumstances, All sales are final, and no exceptions will be made
          </p>
          <p>
            Please carefully review your order before confirming your purchase.
            All sales are considered final. We do not offer refunds or exchanges
            for any products or services sold through <span className="font-semibold"><em>www.offiqltech.com</em></span>.
          </p>
        </div>
        <div class="prose flex flex-col gap-4 max-w-full">
          <p>
            We will not be liable for any product purchased by mistake, In
            circumstances where an extra or a different product is purchased by
            mistake, we would not be accountable for misplacement or replacement
            of the product or service and are not responsible for its delivery
            to the User.
          </p>
          <p>
            By Purchasing our SERVICES or PRODUCTS, you acknowledge and agree to
            this refund policy
          </p>
        </div>

        <div class="prose flex flex-col gap-4 max-w-full">
          
          <h2 className="text-2xl ">
            <strong>Contact Us</strong>
          </h2>
          <p>
            If you have any questions about this Refund Policy, You can contact
            us:
          </p>
          <ul class="font-semibold list-disc pl-[8vw] ">
            <li className="flex gap-2 flex-nowrap md:flex-nowrap">
              <p>By email: </p>
              <p>business@offiql.com</p>
            </li>
          </ul>
        </div>

        <hr />
      </section>
    </div>
  );
}
