export const fello_hero = require('./fello/fello_hero.png');
export const fello_2 = require('./fello/fello_2.png');
export const fello_3 = require('./fello/fello_3.png');
export const fello_4 = require('./fello/fello_4.png');
export const fello_5 = require('./fello/fello_5.png');
export const fello_6 = require('./fello/fello_6.png');

export const desmantle_1 = require('./desmantle/desmantle_1.png')
export const desmantle_2 = require('./desmantle/desmantle_2.png')
export const desmantle_3 = require('./desmantle/desmantle_3.png')
export const desmantle_4 = require('./desmantle/desmantle_4.png')
export const desmantle_5 = require('./desmantle/desmantle_5.png')

export const zing_1 = require('./zing/zing_1.png')
export const zing_2 = require('./zing/zing_2.png')
export const zing_3 = require('./zing/zing_3.png')
export const zing_4 = require('./zing/zing_4.png')
export const zing_5 = require('./zing/zing_5.png')

export const uden_1 = require('./uden/uden_1.jpg');
export const uden_2 = require('./uden/uden_2.jpg');
export const uden_3 = require('./uden/uden_3.png');
export const uden_4 = require('./uden/uden_4.png');
export const uden_5 = require('./uden/uden_5.jpg');

export const od_1 = require('./od/od_1.jpg');
export const od_2 = require('./od/od_2.png');
export const od_3 = require('./od/od_3.png');
export const od_4 = require('./od/od_4.png');
export const od_5 = require('./od/od_5.png');

export const coin1 = require('./killshiba/coin1.png');
export const coin2 = require('./killshiba/coin2.png');
export const coin3 = require('./killshiba/coin3.png');
export const coin4 = require('./killshiba/coin4.png');



export const gg1 = require('./ggnation/gg1.png');
export const gg2 = require('./ggnation/gg2.png');
export const gg3 = require('./ggnation/gg3.png');
export const gg4 = require('./ggnation/gg4.png');




