import React from 'react'
import './Testimonial.css'
import messageIcon from '../../Assets/messageIcon.svg'
import person from '../../Assets/person.svg'
import review from '../../Assets/review.svg'
import { Fade } from "react-awesome-reveal";
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination, Navigation } from 'swiper'
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useRef } from 'react'



const Testimonial = () => {
    const slideRef = useRef(null);
    const testimonialData = [
        {
            message: 'They are true professionals who understands your pain points . I got my product built and delivered on time , which is very rare in the industry . I would highly recommend Offiqltech if you are looking to outsource your product development and technology solution for your business ',
            name: 'Sameer Agrawal',
            place: 'Founder, Clapingo',
            initials:"SA"
        },
        {
            message: 'Its an excellent experience to work with enthusiastic, committed and creative professional who respect the timeline without compromising quality . Impressed with the creative solutions and fresh designs.',
            name: 'Abhishek Narayan',
            place: 'Founder, Busyar',
            initials:"AN"
        },
        {
            message: 'Offiqltech team proved to be a reliant technology partner for us. They have been delivering results within budget and time. I’m absolutely satisfied with the quality of their services, their development skills and responsibility as well as the way they manage communication with us. I fully recommend Offiqltech as a reliable IT partner!',
            name: 'Ashutosh Mishra',
            place: 'Founder, Desmantle.studio',
            initials:"AM"
        },
   


    ]
    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height,
        };
    }
    return (
        <>
            <div className='w-full h-[130vw] sm:h-[55vw]  grid place-content-center  ' id='testimonials'>
                <div className='font-bold text-[5vw] sm:text-[2.5vw] font-[poppins] flex justify-center mb-[5vw]'>
                    What our <span style={{ color: '#FF8002' }} className=' mx-[0.5vw]'>Happy</span> Clients Say !
                </div>
                <Fade triggerOnce>
                    <div className='grid grid-cols-3 gap-[2vw]'>
                        <Swiper
                            slidesPerView={getWindowDimensions().width <= 640 ? 1 : 3}
                            spaceBetween={0}                         
                            slidesPerGroup={3}
                            loop={getWindowDimensions().width <= 640 ? true : false}
                            loopFillGroupWithBlank={true}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={false}
                            modules={[Pagination, Navigation]}
                            className="mySwiper"
                        >
                            {testimonialData.map((val, index) => {
                                return (
                                    <div className='w-full'>
                                        <SwiperSlide className='outer-testimonial'>
                                            <TestimonialCard name={val.name} message={val.message} place={val.place} initials={val.initials}/>
                                        </SwiperSlide>
                                    </div>

                                )
                            })}


                        </Swiper>





                    </div>
                </Fade>

            </div>

        </>
    )
}

export default Testimonial

const TestimonialCard = (props) => {

    return (
        <>
            <div className='relative  sm:w-[28vw] sm:h-[14.5vw] bg-[#2F2F2F] rounded-[0.5vw] flex flex-col justify-evenly items-center testimonial-card'>
                <div>
                    <img src={messageIcon} className='message-icon sm:w-[3.65vw] ' />
                </div>
                <div className='testimonial-message   text-white opacity-50 text-left sm:max-w-[22vw] font-light relative -top-[1.5vw] text-[0.8vw]'>
                    {props.message}
                </div>
                <div className='founders absolute top-[53vw] sm:top-[13vw] font-[poppins]    sm:w-[13vw] flex flex-col justify-center'>
                    <div className='w-[15vw] h-[15vw] sm:w-[5vw] sm:h-[5vw] left-[10vw] sm:left-[3.9vw] bg-gray-600 relative testimonial-image'><div>{props.initials}</div></div>
                    <div className='text-center relative  top-[1vw] '>
                        <div className='font-medium text-[4vw] sm:text-[1vw]'> {props.name}</div>
                        <div className='text-[3vw] sm:text-[1vw]'>{props.place}</div>
                        
                    </div>

                </div>


            </div>
        </>
    )
}