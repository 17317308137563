import React, { useEffect } from 'react'
import Contact from '../../Components/Contact Form/Contact'
import od from '../../Assets/portfolio/od/od1.png'
import zing from '../../Assets/portfolio/zing.svg'
import waterproof from '../../Assets/portfolio/waterproof.svg'
import uden from '../../Assets/portfolio/uden/uden.png'
import dragon from '../../Assets/portfolio/dragon.svg'
import flinto from '../../Assets/portfolio/flinto.svg'
import desmantle from '../../Assets/portfolio/desmantle.svg'
import dragonAir from '../../Assets/portfolio/dragonAir.svg'
import fello from '../../Assets/portfolio/fello/fello.png'
import flintoClass from '../../Assets/portfolio/flintoClass.svg'
import gameberry from '../../Assets/portfolio/gameberry.svg'
import token from '../../Assets/portfolio/killshiba/coin4.png'
import logo from '../../Assets/portfolio/logo.svg'
import sportycoon from '../../Assets/portfolio/sportycoon.png'
import { Link } from 'react-router-dom'
import './portfolio.css'
import ScrollToTop from '../../Components/scrollToTop/ScrollToTop'

import clp1 from '../../Assets/portfolio/clapingo/clp1.png'
import offiql3 from '../../Assets/portfolio/offiql/offiql3.png'
import em1 from '../../Assets/portfolio/eventmender/em1.png'
import mayadaar from '../../Assets/portfolio/mayadaar/mayadaar.webp'
import maangal from '../../Assets/portfolio/maangal/maangal.png'
import gg3 from '../../Assets/portfolio/ggnation/gg3.png'
import zing1 from '../../Assets/portfolio/zing/zing1.png'
import desmantle1 from '../../Assets/portfolio/desmantle/desmantle1.png'


const Portfolio = () => {
    useEffect(() => {
        
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [])

    const portfolioData = [
        {
            image: mayadaar,
            heading: "Mayadaar",
            link: "mayadaar"
        },
        {
            image: em1,
            heading: "Eventmender",
            link: "eventmender"
        },
        {
            image: clp1,
            heading: "Clapingo",
            link: "clapingo"
        },

        {
            image: od,
            heading: "OD Works :  B2B SaaS Platform",
            link: "OD_Works"
        },
        {
            image: zing1,
            heading: "Zing : Hyperlocal Food app with a zingy twist",
            link: "zing"
        },

        {
            image: uden,
            heading: "UDEN : Upskilling and Matching Platform",
            link: "uden"
        },
        {
            image: sportycoon,
            heading: "SportyCoon:Sports betting platform",
            link: "sportycoon"
        },
        // {
        //     image: dragon,
        //     heading: "Dragonair Aviation: Jellyfish",
        //     link: "dragon"
        // },
        // {
        //     image: flinto,
        //     heading: "Flintoclass: Packaging Experience Redesign",
        //     link: "flinto"
        // },
    ]
    const portfolioData1 = [
        {
            image: maangal,
            heading: "Maangal",
            link: "maangal"
        },
        {
            image: gg3,
            heading: "GoodGame Nation",
            link: "ggnation"
        },
        {
            image: offiql3,
            heading: "OFFIQL Advertising Technologies",
            link: "offiql"
        },

        {
            image: fello,
            heading: "Fello : Gamified Investments",
            link: "fello"
        },

        {
            image: desmantle1,
            heading: "Desmantle: Interaction Design",
            link: "desmantle"
        },
        {
            image: token,
            heading: "Killshiba Token: Cryptocoin Website",
            link: "killshiba"
        },

    ]
    return (
        <>

            <div className='bg-white h-auto relative'>
                <div className='p-[3vw]'>
                    <Link to='/' >
                        <div className='absolute top-[3vw] left-[6.7vw] '  >
                            <img src={logo} alt='logo' className='sm:w-[10vw] portfolio-logo-mob' />
                        </div>
                    </Link>
                    <Link to='/'>
                        <div className='absolute sm:top-[7.5vw] top-[12vw]  left-[7vw]'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                            </svg>

                        </div>
                    </Link>
                    <div className='portfolio-outside'>
                        <div className='text-center flex flex-col justify-center items-center'>
                            <div className='text-black text-[3vw] font-semibold font-[poppins] portfolio-main-heading-mob'>
                                Portfolio
                            </div>
                            <div className='sm:w-[45vw] justify-center flex font-[poppins] text-[1.2vw] portfolio-main-subheading-mob'>
                                Have a look on some of our fine works and services provided to our happy client all around the world.
                            </div>
                        </div>

                        <div className='flex flex-row justify-evenly my-[5vw]'>
                            <div className='flex-col justify-evenly '>
                                {portfolioData.map((val, index) => {
                                    return (
                                        <PortfolioCards image={val.image} heading={val.heading} link={val.link} />
                                    )
                                })}

                            </div>
                            <div className='flex-col justify-evenly relative top-[12vw]'>
                                {portfolioData1.map((val, index) => {
                                    return (
                                        <PortfolioCards image={val.image} heading={val.heading} link={val.link} />
                                    )
                                })}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <Contact />
            <ScrollToTop />
        </>
    )
}

export default Portfolio

const PortfolioCards = (props) => {
    return (

        <>
            <Link to={`/portfolio/${props.link}`} >

                <div className='flex flex-col  mb-[20vw]' style={{ maxheight: '25vw', maxwidth: '40vw' }}>
                    <img src={props.image} className="shadow-lg  " style={{ objectFit: 'contain', height: 'auto', width: '40vw' }} />
                    <div className='mt-[2vw] text-black text-[1.3vw] font-[poppins] font-bold portfolio-heading'>
                        {props.heading}
                    </div>
                </div>
            </Link>
        </>
    )
}