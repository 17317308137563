import React, { useState } from 'react'
import './ServicesCard.css'
import { Fade } from "react-awesome-reveal";
import Questionaire from '../Questionaire/Questionaire'

const ServicesCard = (props) => {
    const [isOpen, setOpen] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const setModalState = (value) => {
        setIsModalOpen(value)
    }
    const [increment, setIncrement] = useState(0)
    const [isButtonActive , setIsButtonActive] = useState(false)



    return (
        <>
      <Fade triggerOnce>

            <div 
            data-aos="fade-up"
            onMouseEnter={()=> setIsButtonActive(true)}
            onMouseLeave={()=> setIsButtonActive(false)}
            className='cursor-pointer w-[34vw] h-[27vw] services-card md:w-[17vw] md:h-[13vw] bg-white transition duration-300  hover:text-black rounded-[13px] serviceCard'>
                <div className='p-[3.4vw] sm:p-[2vw] flex flex-col '>
                    <div className={isButtonActive  ? 'rounded-[0.2vw] transition-duration-300 sm:w-[2.4vw] w-[4vw] h-[4vw] sm:h-[2vw] bg-transprent services-box-shadow border-[0.1vw] border-black grid place-content-center' : 'w-[4vw] sm:w-[2.4vw] sm:h-[2vw] bg-transprent services-box-shadow grid place-content-center'}>
                        <img src={props.image} className=' sm:w-[1.1vw]' />
                    </div>
                    <div className='text-[3vw] sm:text-[1vw] font-[poppins] max-w-[27vw] sm:max-w-[8vw]  text-left mt-[1vw]'>
                        {props.title}
                    </div>
                    <div className='text-[2vw] sm:text-[0.8vw] font-[poppins] text-[#2F2F2F]  text-left my-[0.7vw]'>
                       {props.subtitle}
                    </div>
                    <div 
                     onClick={() => {
                        setIsModalOpen(true);
                        setIncrement(0)
                    }}
                    className={isButtonActive ? 'transition duration-300 text-[2vw] w-[15vw] h-[6vw] sm:text-[0.8vw]  font-[poppins] flex justify-center items-center  text-left sm:w-[6vw]  sm:h-[2.5vw] rounded-[1vw] sm:rounded-[0.5vw] bg-black text-white' :'text-[2vw] sm:text-[0.8vw] font-medium font-[poppins] flex justify-center items-center  text-left w-[15vw] h-[6vw] sm:w-[6vw]  sm:h-[2.5vw] rounded-[1vw] sm:rounded-[0.5vw] border-[2px] border-[#f97440] text-[#f97440]'}>
                        Get Started
                    </div>
                </div>
            </div>
            </Fade>
          
        </>
    )
}

export default ServicesCard