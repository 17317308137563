import React, { useEffect } from 'react'
import Contact from '../Contact Form/Contact'
import { Fade } from 'react-awesome-reveal'
import logo from '../../Assets/portfolio/logo.svg'
import { Link } from 'react-router-dom'
import ScrollToTop from '../scrollToTop/ScrollToTop'


const PortfolioCompanyComponent = (props) => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [])

    return (
        <>
            <div className='w-full h-auto grid place-content-center'>
                <Link to='/'>
                    <div className='absolute top-[1vw] left-[3vw]'>
                        <img src={logo} alt='logo' className='w-[10vw] portfolio-profile-logo-mob' />
                    </div>
                </Link>
                <Link to='/portfolio'>
                    <div className='absolute top-[10vw] sm:top-[4vw] left-[3vw]'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                        </svg>

                    </div>
                </Link>

                <div className='absolute top-[-5vw] right-[-15vw]  w-[30vw] h-[30vw]  z-10 rounded-full bg-orange-500' style={{ zIndex: "1" }}></div>
                <div className={props.heading === 'sportycoon' ? "hidden" : 'absolute top-[65vw] left-[-30vw]  w-[50vw] h-[50vw] z-10 duration-300  rounded-full bg-orange-500'} style={{ zIndex: "1" }}></div>
                <div className={props.heading === 'sportycoon' ? "hidden" : 'absolute top-[135vw] right-[-30vw]  w-[50vw] h-[50vw] rounded-full bg-orange-500 z-10 duration-300 '} style={{ zIndex: "1" }}></div>
                {props.heading === 'Maangal' || props.heading === "Mayadaar" ? <></> : <div className='absolute top-[195vw] left-[-30vw]  w-[50vw] h-[50vw] rounded-full bg-orange-500 z-10 duration-300 ' style={{ zIndex: "1" }}></div>}

                {props.heading === 'desmantle' || props.heading === "sportycoon" ? <div className={props.heading === 'desmantle' || 'sportycoon' ? ' w-auto sm:h-screen grid place-items-center portfolio-profile-mob-video ' : "hidden"}>
                    <iframe src={props.heading === 'desmantle' ? 'https://www-ccv.adobe.io/v1/player/ccv/V-PvpEv3r6S/embed?api_key=behance1&bgcolor=%23191919' : props.heading === 'sportycoon' ? 'https://www-ccv.adobe.io/v1/player/ccv/KpFZeKrP22S/embed?api_key=behance1&bgcolor=%23191919' : ''} className={props.heading === 'desmantle' || 'sportycoon' ? 'w-[80vw] h-[40vw] ' : 'hidden'} style={{ zIndex: "1" }} />


                </div> : <></>}
                <div className={props.heading === 'sportycoon' ? "hidden" : props.grid ? 'p-[3vw] mt-[15vw] sm:mt-[5vw] sm:mb-[25vw] z-20 space-y-[5vw] grid grid-cols-2 gap-y-[8vw] gap-[4vw]' : 'mt-[15vw] sm:mt-[5vw]  p-[3vw]  z-20 space-y-[5vw]'} style={{ zIndex: "20" }}>

                    <div className={props.heading === 'desmantle' || 'zing' ? 'w-auto portfolio-profile-mob sm:h-screen grid place-items-center ' : 'w-auto sm:h-screen'}>
                        <img src={props.image1} alt='' className={props.grid ? 'w-[25vw] shadow-lg' : props.heading === 'killshiba' ? "w-[50vw]  h-[100vw] shadow-lg mb-[15vw]" : props.heading === 'desmantle' ? 'w-[80vw] h-[40vw] shadow-lg ' : props.heading === 'zing' ? 'w-[50vw] shadow-lg  h-[40vw] ' : props.heading === 'uden' ? 'w-[70vw] h-[40vw] shadow-lg  ' : 'w-auto h-auto shadow-lg  '} />
                    </div>


                    <div className={props.heading === 'desmantle' || 'zing' ? 'w-auto sm:h-screen portfolio-profile-mob grid place-items-center ' : 'w-auto sm:h-screen'}>
                        <img src={props.image2} alt='' className={props.grid ? 'w-[25vw] shadow-lg' : props.heading === 'killshiba' ? "w-auto  h-auto shadow-lg md:mt-[45vw]" : props.heading === 'desmantle' ? 'w-[80vw] h-[40vw] shadow-lg  ' : props.heading === 'zing' ? 'w-[50vw] shadow-lg  h-[40vw] ' : props.heading === 'uden' ? 'w-[70vw] h-[40vw] shadow-lg  ' : 'w-auto h-auto shadow-lg  '} />

                    </div>


                    <div className={props.heading === 'desmantle' || 'zing' ? 'w-auto sm:h-screen portfolio-profile-mob grid place-items-center ' : 'w-auto sm:h-screen'}>
                        <img src={props.image3} alt='' className={props.grid ? 'w-[25vw] shadow-lg' : props.heading === 'desmantle' ? 'w-[80vw] h-[40vw] shadow-lg  ' : props.heading === 'zing' ? 'w-[50vw] shadow-lg  h-[40vw] ' : props.heading === 'uden' ? 'w-[70vw] h-[40vw] shadow-lg  ' : 'w-auto h-auto shadow-lg  '} />

                    </div>


                    <div className={props.heading === 'desmantle' || 'zing' ? 'w-auto sm:h-screen portfolio-profile-mob grid place-items-center ' : 'w-auto sm:h-screen'}>
                        <img src={props.image4} alt='' className={props.grid ? 'w-[25vw] shadow-lg' : props.heading === 'killshiba' ? " shadow-lg mt-[15vw]" : props.heading === 'desmantle' ? 'w-[80vw] h-[40vw] shadow-lg  ' : props.heading === 'zing' ? 'w-[50vw] shadow-lg  h-[40vw] ' : props.heading === 'uden' ? 'w-[70vw] h-[40vw] shadow-lg  ' : 'w-auto h-auto shadow-lg  '} />

                    </div>


                    <div className={props.heading === 'desmantle' || 'zing' ? 'w-auto sm:h-screen portfolio-profile-mob grid place-items-center ' : 'w-auto sm:h-screen'}>
                        <img src={props.image5} alt='' className={props.grid ? 'w-[25vw] shadow-lg' : props.heading === 'desmantle' ? 'w-[80vw] h-[40vw] shadow-lg   ' : props.heading === 'zing' ? 'w-[80vw] shadow-lg  h-[40vw] ' : props.heading === 'uden' ? 'w-[60vw] h-[40vw] shadow-lg  ' : 'w-auto h-auto shadow-lg  '} />

                    </div>
                    <div className={props.heading === 'desmantle' || 'zing' ? 'w-auto sm:h-screen portfolio-profile-mob grid place-items-center ' : 'w-auto sm:h-screen'}>
                        <img src={props.image6} alt='' className={props.grid ? 'w-[25vw] shadow-lg' : props.heading === 'desmantle' ? 'w-[80vw] h-[40vw] shadow-lg   ' : props.heading === 'zing' ? 'w-[80vw] shadow-lg  h-[40vw] ' : props.heading === 'uden' ? 'w-[60vw] h-[40vw] shadow-lg  ' : 'w-auto h-auto shadow-lg  '} />

                    </div>
                    <div className={props.heading === 'desmantle' || 'zing' ? 'w-auto sm:h-screen portfolio-profile-mob grid place-items-center ' : 'w-auto sm:h-screen'}>
                        <img src={props.image7} alt='' className={props.grid ? 'w-[25vw] shadow-lg' : props.heading === 'desmantle' ? 'w-[80vw] h-[40vw] shadow-lg   ' : props.heading === 'zing' ? 'w-[80vw] shadow-lg  h-[40vw] ' : props.heading === 'uden' ? 'w-[60vw] h-[40vw] shadow-lg  ' : 'w-auto h-auto shadow-lg  '} />

                    </div>
                    <div className={props.heading === 'desmantle' || 'zing' ? 'w-auto sm:h-screen portfolio-profile-mob grid place-items-center ' : 'w-auto sm:h-screen'}>
                        <img src={props.image8} alt='' className={props.grid ? 'w-[25vw] shadow-lg' : props.heading === 'desmantle' ? 'w-[80vw] h-[40vw] shadow-lg   ' : props.heading === 'zing' ? 'w-[80vw] shadow-lg  h-[40vw] ' : props.heading === 'uden' ? 'w-[60vw] h-[40vw] shadow-lg  ' : 'w-auto h-auto shadow-lg  '} />

                    </div>


                    {props.heading === 'desmantle' || 'zing' || 'uden' ?

                        <div className={props.heading === 'desmantle' || 'zing' ? 'w-auto sm:h-screen hidden   place-items-center ' : 'w-auto sm:h-screen '}>
                            <img src={props.image6} alt='' className={props.heading === 'desmantle' ? 'w-[80vw] shadow-lg  h-[40vw] ' : props.heading === 'zing' ? 'w-[80vw] shadow-lg  h-[40vw] ' : ''} />

                        </div>
                        : ""}
                </div>
            </div>
            <Contact />
            <ScrollToTop />

        </>
    )
}

export default PortfolioCompanyComponent