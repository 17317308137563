import { useEffect } from "react";

export function PricingPolicy() {
  useEffect(() => {
    const element = document.getElementById("pricingPolicy");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  return (
    <div id="pricingPolicy" className="min-h-screen w-screen">
      <header class="bg-black text-white text-center py-4">
        <h1 class="text-3xl font-bold">
          <strong>Pricing Policy</strong>
        </h1>
        <p>
          Welcome to{" "}
          <a href="https://offiqltech.com/" target="_blank" class="underline">
            www.offiqltech.com
          </a>
        </p>
      </header>

      <section class="container max-w-4xl mx-auto flex flex-col gap-7 my-8 px-3">
      <div class="prose flex flex-col gap-4 max-w-full">
          <h1 class="text-3xl font-bold mb-4">Pricing Policy</h1>

          <p class="mb-4">
            This Pricing Policy applies to all services provided by
            <em>offiql</em> to service seekers . By using our
            services, you agree to comply with this Policy.
          </p>

          <h2 class="text-xl font-bold mb-2">1. Pricing Structure:</h2>

          <p class="mb-2">1.1. Service Rates:</p>
          <p>
            Service rates are determined based on factors such as the type of
            service, duration, and any additional features or requirements. The
            rates for each service will be clearly communicated to Service
            Seekers during the booking process.
          </p>

          <p class="mb-2">1.2. Additional Charges:</p>
          <p>
            Additional charges may apply for extra services or special requests
            made by the Service Seeker. These charges will be communicated and
            agreed upon before the service is provided.
          </p>

          <h2 class="text-xl font-bold my-4">2. Payment Terms:</h2>

          <p class="mb-2">2.1. Payment Methods:</p>
          <p>
            Service Seekers can choose their preferred payment method
            during the booking process.
          </p>

          <p class="mb-2">2.2. Payment Schedule:</p>
          <p>Payment is due within 2 days from the completion of the service. Late payments beyond this period may be subject to additional fees </p>

          <h2 class="text-xl font-bold mt-4">3. Cancellation and Refunds:</h2>

          <p class="mb-2">3.1. Cancellation by Service Seekers:</p>
          <p>
            Service Seekers may cancel a service booking within [X] hours/days
            of the scheduled service time. Cancellation charges may apply, and
            details will be provided during the booking process.
          </p>

          <p class="mb-2">3.2. Cancellation by Service Providers:</p>
          <p>
            If a Service Provider cancels a confirmed booking, the Service
            Seeker will not be charged any cancellation fees.
          </p>

          <h2 class="text-xl font-bold mt-4">4. Changes and Notifications:</h2>

          <p class="mb-2">4.1. Changes to Pricing Policy:</p>
          <p>
            The Company reserves the right to modify or update this Pricing
            Policy at any time. Changes will be effective immediately upon
            posting on the Company's website.
          </p>

          <p class="mb-2">4.2. Notifications:</p>
          <p>
            Service Seekers will be notified of any
            changes to the Pricing Policy through the Company's communication
            channels.
          </p>
        </div>
      </section>
    </div>
  );
}
