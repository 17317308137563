import React from 'react'
import { ThreePointerSection } from '../../Components/Three pointers/ThreePointers'
import CaseStudy from '../../Components/Case Studies/CaseStudy'
import Contact from '../../Components/Contact Form/Contact'
import CustomCursor from '../../Components/CustomCursor/CustomCursor'
import FAQ from '../../Components/FAQ/FAQ'
import Features from '../../Components/Features Section/Features'
import HeroSection from '../../Components/HeroSection/HeroSection'
import MobHeroSection from '../../Components/HeroSection/MobHeroSection'
import LetsConnect from '../../Components/LetsConnect/LetsConnect'
import Navbar from '../../Components/Navbar/Navbar'
import ServicesSection from '../../Components/ServicesCardSection/ServicesSection'
import Testimonial from '../../Components/Testimonial/Testimonial'

const Home = ({ portfolioImages }) => {
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  return (
    <>
      {/* <Navbar/> */}
      {/* <CustomCursor/>  */}
      {getWindowDimensions().width <= 640 ? <MobHeroSection /> : <HeroSection />
      }
      <ThreePointerSection />
      <ServicesSection />
      <Testimonial />
      <CaseStudy portfolioImages={portfolioImages} />
      <LetsConnect />
      <Features />
      {/* <FAQ /> */}
      <Contact />

    </>
  )
}

export default Home