import { useEffect } from "react";

export function CancellationPolicy() {
  useEffect(() => {
    const element = document.getElementById("cancellationPolicy");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  return (
    <div id="cancellationPolicy" className="min-h-screen w-screen">
      <header class="bg-black text-white text-center py-4">
        <h1 class="text-3xl font-bold">
          <strong>Cancellation Policy</strong>
        </h1>
        <p>
          Welcome to{" "}
          <a href="https://offiqltech.com/" target="_blank" class="underline">
            www.offiqltech.com
          </a>
        </p>
      </header>

      <section class="container max-w-4xl mx-auto flex flex-col gap-7 my-8 px-3">
        <div class="prose flex flex-col gap-4 max-w-full">
          <h1 class="text-3xl font-bold mb-4">Cancellation Policy</h1>

          <p class="mb-4">
            This Cancellation Policy applies to all services and products
            provided by <em className="font-semibold">offiql</em> to service
            seekers . By using our services, you agree to comply with this
            Policy.
          </p>
        </div>
        <div class="prose flex flex-col gap-4 max-w-full">
          <h2 class="text-xl font-bold mb-2">
            1. Cancellation by Service Seekers:
          </h2>

          <p class="mb-2">1.1. Cancellation Period:</p>
          <p>
            Service Seekers may cancel a service booking within 48 hours/days of
            the scheduled service time.
          </p>

          <p class="mb-2">1.2. Cancellation Charges:</p>
          <p>
            Cancellation within the specified cancellation period may be subject
            to a cancellation fee. The exact fee will be communicated to the
            Service Seeker during the booking process.
          </p>
        </div>
        <div class="prose flex flex-col gap-4 max-w-full">
          <h2 class="text-xl font-bold my-4">
            2. Cancellation by Service Providers:
          </h2>

          <p class="mb-2">2.1. Unavailability:</p>
          <p>
            In the event that a Service Provider becomes unavailable due to
            unforeseen circumstances, the Company will make reasonable efforts
            to provide an alternative Service Provider. If an alternative cannot
            be arranged, the Company may cancel the service booking and notify
            the Service Seeker.
          </p>

          <p class="mb-2">2.2. Cancellation Charges:</p>
          <p>
            If a Service Provider cancels a confirmed booking, the Service
            Seeker will not be charged any cancellation fees.
          </p>
        </div>
        <div class="prose flex flex-col gap-4 max-w-full">
          <h2 class="text-xl font-bold mt-4">3. Changes and Notifications:</h2>

          <p class="mb-2">3.1. Changes to Cancellation Policy:</p>
          <p>
            The Company reserves the right to modify or update this Cancellation
            Policy at any time. Changes will be effective immediately upon
            posting on the Company's website.
          </p>

          <p class="mb-2">3.2. Notifications:</p>
          <p>
            Service Seekers will be notified of any changes to the Cancellation
            Policy through the Company's communication channels.
          </p>
        </div>

        <div class="prose flex flex-col gap-4 max-w-full">
          <h2 className="text-xl font-bold mt-4 ">
            <strong>4. Contact Us</strong>
          </h2>
          <p>
            If you have any questions about this Cancellation Policy, You can
            contact us:
          </p>
          <ul class="font-semibold list-disc pl-[8vw] ">
            <li className="flex gap-2 flex-nowrap md:flex-nowrap">
              <p>By email: </p>
              <p>business@offiql.com</p>
            </li>
          </ul>
        </div>

        <hr />
      </section>
    </div>
  );
}
