import React from 'react'
import brush from '../../Assets/pointers/brush.svg'
import execution from '../../Assets/pointers/execution.svg'
import feedback from '../../Assets/pointers/feedback.svg'
import './ThreePointers.css'

const ThreePointers = (props) => {
    return (
        <>
            <div className='   text-center mr-[4vw] mb-[8vw] sm:mb-0 grid place-content-center'>
                <div className='  grid place-content-center  rounded-[0.6vw]'>
                    <div className='bg-[#F9C240] sm:w-[4vw] sm:h-[4vw] w-[10vw] h-[10vw] grid place-content-center rounded-[1vw]'>
                        <img src={props.image} alt="image" className=' sm:w-[2vw] sm:h-[2vw] w-[4vw] h-[4vw] ' />

                    </div>
                </div>
                <div className='text-black font-[poppins] font-bold text-[4vw] sm:text-[1.5vw] '>
                    {props.heading}
                </div>
                <div className='text-center text-[2.5vw] sm:text-[1vw] text-black font-normal max-w-[45vw] sm:max-w-[23vw]'>
                    {props.subHeading}
                </div>
            </div>
        </>
    )
}

export default ThreePointers

export const ThreePointerSection = () => {
    const threePointerArray = [
        {
            image: brush,
            heading: 'Design',
            subHeading: "A unique and symmetric representation is the key to getting attention",
        },
        {
            image: feedback ,
            heading: 'Feedback ',
            subHeading: "Business Insights added to visual representation are equal to satisfactory output.",
        },
        {
            image: execution,
            heading: 'Execution',
            subHeading: "Engineering Rockstars handle this phase and carve our robust and neat code.",
        },
    ]
    return (
        <>
            <div className='w-full h-[130vw] sm:h-[30vw] bg-white grid  place-content-center relative  mt-[5vw] left-[2vw] sm:left-0'>
                <div className='flex flex-col sm:flex-row justify-center'>
                    {threePointerArray.map((val, index) => {
                        return (
                            <ThreePointers
                                heading={val.heading}
                                subHeading={val.subHeading}
                                image={val.image}
                            />
                        )
                    })}
                </div>
            </div>
        </>
    )

}