import React from 'react'
import video from '../../Assets/video.svg'
import './LetsConnect.css'
import { Fade } from 'react-awesome-reveal'
import businessGIF from '../../Assets/businessGif.gif'

const LetsConnect = () => {
    const LetsConnectPointersData = [
        {
            heading: "1.Discover",
            subHeading: " We understand your business needs to then research and analyze options as well as find a better solution."
        },
        {
            heading: "2.Implement",
            subHeading: "We develop an MVP (Minimum Viable Product) with a quick proposal and solution."
        },
        {
            heading: "3.Evolve ",
            subHeading: "We maintain and adapt products using agile methodologies always prioritizing your business."
        },
    ]
    return (
        <>
            <div id='company' className='h-[210vw] sm:h-[45vw] bg-[#2F2F2F] my-[4vw] flex justify-center relative'>
                <div className='flex  sm:flex-row lets-connect-section space-between pt-[14vw] sm:py-[2vw] '>
                    {/* Lets Connect text */}
                    <div className='flex flex-col relative sm:left-[3vw] '>
                        <Fade triggerOnce>
                            <div className='text-[#FF8003] text-[2.5vw] sm:text-[1vw] font-[poppins] lets-connect-title'>Lets Connect</div>
                        </Fade>
                        <Fade triggerOnce>
                            <div className='text-[#FAFAFA] text-[4vw] sm:text-[2vw] lets-connect-heading  sm:left-0 left-[10vw] relative'>Getting your
                                <span className='text-[#FF8003] pl-[1vw]'>
                                    Business
                                </span><br />
                                Connecting to
                                your Audience
                            </div>
                        </Fade>
                        <Fade triggerOnce>
                            <div className='flex  lets-connect-numbers flex-row divide-x-[0.15vw] divide-[#FF8003] mt-[2vw] font-[poppins] relative sm:left-[-2.5vw]'>
                                <div className='text-[4vw] sm:text-[1.2vw] text-[#FF8003]  font-light px-[3vw] flex flex-col' >
                                    <div>21k+</div>
                                    <div className='text-white text-[2.5vw] sm:text-[0.6vw] md:text-[1vw] 2xl:text-[1vw] opacity-80 tracking-wide'>Investors</div>
                                </div>
                                <div className='text-[4vw] sm:text-[1.2vw] text-[#FF8003]  font-light px-[3vw] flex flex-col' >
                                    <div>10k+</div>
                                    <div className='text-white text-[2.5vw] sm:text-[0.6vw] md:text-[1vw] 2xl:text-[1vw] opacity-80 tracking-wide'>
                                        Opened businesses
                                    </div>
                                </div>
                                <div className='text-[4vw] sm:text-[1.2vw] text-[#FF8003]  font-light px-[3vw] flex flex-col' >
                                    <div>
                                        4k+
                                    </div>
                                    <div className='text-white text-[2.5vw] sm:text-[0.6vw] md:text-[1vw] 2xl:text-[1vw] opacity-80 tracking-wide'>
                                        New Projects
                                    </div>
                                </div>


                            </div>
                        </Fade>
                        <Fade triggerOnce>
                            <div className='flex flex-col mt-[2vw]'>
                                {LetsConnectPointersData.map((val, index) => {
                                    return (
                                        <LetsConnectPoints
                                            heading={val.heading}
                                            subHeading={val.subHeading}
                                        />
                                    )
                                })}
                            </div>
                        </Fade>

                    </div>
                    {/* Lets Concect gif */}
                    <Fade triggerOnce>
                        <div className='flex  flex-row sm:flex-col sm:relative mt-[20vw] sm:mt-[2vw] sm:top-[-5vw] sm:right-[-6vw] '>
                            <div>
                                <img src={businessGIF} alt="gif" className='h-[70vw] w-screen sm:h-[46vw] sm:w-[50vw]' />
                            </div>
                        </div>
                    </Fade>
                </div>




            </div>

        </>
    )
}

export default LetsConnect

const LetsConnectPoints = (props) => {
    return (
        <>
            <div className=' flex flex-col my-[5vw] sm:my-[1.5vw] '>
                <div className='text-[#FE8003] sm:text-[1vw] mb-[0.5vw]'>{props.heading}</div>
                <p className='text-white opacity-70 font-[poppins] sm:text-[1vw] max-w-[70vw] relative left-[14vw] sm:left-0  sm:max-w-[35vw] font-normal'>
                    {props.subHeading}

                </p>
            </div>
        </>
    )
}