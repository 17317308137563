import React, { createContext, useReducer } from 'react'

const initialState = {
    count: 0,
    text: '',
}

export const Context = createContext(initialState) // <- initialize Context using initialState

export const Store = ({ children }) => {
    const [state, dispatch] = useReducer((state, action) => {
        switch (action.type) {
            case 'update_text':
                return { ...state, text: action.payload }

            default:
                return state
        }
    },initialState)
    return (
        <Context.Provider value={[state,dispatch]}> {/* <- this value is gonna be Global state */}
            {children}
        </Context.Provider>
    )
}