import React, { useState } from 'react'
import './HeroSection.css'
import HeroImage from '../../Assets/HeroImage.png'
import HeroColab from '../../Assets/HeroColab.png'
import snake from '../../Assets/snake.svg'
import plane from '../../Assets/plane.svg'
import stringyArrow from '../../Assets/stringyArrow.svg'
import redCard from '../../Assets/redCard.svg'
import rightChevron from '../../Assets/rightChevron.svg'
import downChevron from '../../Assets/downChevron.svg'
import Questionaire from '../Questionaire/Questionaire'
import { Spiral as Hamburger } from 'hamburger-react'
import ScrollToTop from '../scrollToTop/ScrollToTop'

const HeroSection = () => {
    const [isOpen, setOpen] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const setModalState = (value) => {
        setIsModalOpen(value)
    }
    const [increment, setIncrement] = useState(0)
    const questionaireData = [
        {
            title: "Let's get you started quickly",
            question1: 'For us to reach out, please share your Email id',
            question2: "What's your first name",
            question3: "Please share your phone number"

        },
        {
            title: "Perfect, just a few things left !",
            question1: "Tell us, what are your requirements",
            question2: "Approximately , how long do you expect to be working with them ?",
            question3: "Where did you hear about us ?",
            dropdownContent1: ['Product Designer', 'Web Developer ', 'UI/UX designer', 'App developer', 'Graphic designer'],
            dropdownContent2: ['1-3 months ', '3-6 months', '6-9 months', '9-12 months'],
            dropdownContent3: ['Instagram', 'Twitter', 'Facebook', 'Friends', 'others'],
        },
        {
            title: "",
            calendlyLink: 'https://calendly.com/pulkitmittalbusiness'

        },
        {
            title: "Wohoo ! We've received your talent requirements.",
            successMessage: 'Within the next 24-48 hours, you will hear from our Talent Matching expert who will sharepersonalized talent recommendations for your project.',

        },

    ]
    const setIncrementValue = (value) => {
        setIncrement(increment + value)

    }
    const scrollFunction = () => {
        window.scrollTo(0, 500)
    }
    return (
        <>
            <div className='font-[poppins] cursor-pointer text-[1.2vw] 2xl:text-[1vw] font-medium burger relative'>
                <nav className='text-white' style={{ right: !isOpen ? '-250vh' : '0', position: 'fixed' }}>
                    {/* address section */}
                    <div className=' absolute flex flex-col  logo-hamburger-temp font-[poppins] 2xl:text-[3vw] text-white md:text-[2.6vw] sm:text-[3vw]  font-medium  tracking-widest'>
                        <div className=' relative left-[-2.5vw]'><span className='text-[#FF7C02]'>O</span>ffiqltech</div>
                        <div className=' tracking-normal text-left flex flex-col font-[poppins] text-[0.9vw] font-medium'>

                            <div className=' mb-[1vw] '>Offiql Technologies Pvt. Ltd</div>
                            <div className='mb-[0.5vw] flex flex-row '>
                                <div className='w-[2vw]'>
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
                                    </svg>
                                </div>
                                <div className=''>
                                    Shubhash Road, Dehradun,<br />
                                    Uttarakhand 248001
                                </div>
                            </div>

                            <div className='mb-[0.5vw] flex flex-row'>
                                <div className='w-[2vw]'>
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                    </svg>
                                </div>
                                <div>
                                    business@offiqltech.com
                                </div>


                            </div>
                            <div className='flex flex-row'>
                                <div className='w-[2vw]'>
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                                    </svg>
                                </div>
                                <div>
                                    +91 82795 09827
                                </div>

                            </div>
                        </div>
                    </div>
                    <ul>
                        <li><a href="#services" onClick={() => setOpen(false)}>Services </a></li>
                        <li ><a href="#company">Company</a></li>
                        <li ><a href="#portfolio" onClick={() => setOpen(false)}>Portfolio</a></li>
                        <li ><a href="#testimonials" onClick={() => setOpen(false)}>Testimonials</a></li>
                        <li ><a href="#contact" onClick={() => setOpen(false)}>Contact</a></li>
                    </ul>
                    {/* <div className='close-icon'>
                        <Hamburger toggled={isOpen} toggle={setOpen} />

                    </div> */}
                    <div className='big-fat-orange-circle' style={{ right: !isOpen ? '-100vh' : '80vw' }}>

                    </div>

                </nav>


            </div>

            <div className='w-full h-[50vw]  flex flex-row justify-between m-auto relative'>




                <div className='absolute top-[2vw] burger hamburger-temp '>
                    <Hamburger toggled={isOpen} toggle={setOpen} color="#ffff" />
                </div>

                {/* Hero Heading */}
                <div className='flex flex-col justify-center items-center pl-[5vw] relative sm:top-[2vw] xl:-top-[2vw]'>
                    {/* Main Heading */}
                    <div className='font-[poppins] text-[3.5vw] font-bold bounce-text'>

                        Scale Your Business <br />
                        We create a <span style={{ color: '#FF8002' }}>Difference.</span>
                    </div>
                    {/* Subheading */}
                    <div className='font-[poppins] text-[1.2vw] w-[41vw] h-[133px]  mt-[2vw]  '>
                        Transforming businesses with powerful and adaptable digital solutions that satisfy the needs of today.
                        {/* CTA Buttons */}
                        <div className='flex flex-row space-x-[2vw] relative  top-[2.3vw] hero-responsive'>
                            <div
                                onClick={() => {
                                    setIsModalOpen(true);
                                    setIncrement(0)
                                }}
                                className='cursor-pointer  hover:bg-gray-900 transition duration-300 rounded-[0.85vw] sm:w-[12vw] sm:h-[3.8vw] bg-black text-white flex flex-row justify-evenly items-center font-[poppins] text-[1.1vw] font-medium'>
                                <span>Get Started</span> <span><img src={rightChevron} alt='' className='w-[1.2vw]' /></span>
                            </div>
                            <a href='#services' >
                                <div className='cursor-pointer hover:bg-[#FF8002] transition duration-300 rounded-[0.85vw] sm:w-[12vw] sm:h-[3.8vw] bg-transparent border-[0.2vw] border-black text-black flex flex-row justify-evenly items-center font-[poppins] text-[1.1vw] font-medium'>
                                    <span>Scroll Down</span> <span><img src={downChevron} alt='' className='w-[1.2vw]' /></span>
                                </div>
                            </a>
                        </div>
                    </div>

                </div>

                {/* Right Content */}
                <div className='hero-subgradient w-[30vw] '>
                    <div className='absolute font-[poppins] 2xl:text-[3vw] text-black md:text-[2.6vw] sm:text-[3vw] font-medium top-[1vw] left-[4vw] tracking-wide'>
                        <span className='text-[#FF7C02]'>O</span>ffiql<span className='text-[#FF7C02]'>tech</span>
                    </div>
                    <img src={snake} className="absolute top-[2vw] right-[2.7vw] sm:w-[] sm:h-[]" />
                    <img src={plane} className="absolute top-[9vw] right-[10.7vw] z-30 " />
                    <img src={stringyArrow} className="absolute top-[11vw] right-[43.7vw] sm:w-[6.5vw] sm:h-[7.5vw]" />
                    <div className='relative top-[12vw] -left-[12.3vw] z-50'>

                        <img src={HeroImage} alt='HeroImage' className='w-[26vw] z-20' />
                        <div className='w-[11.2vw] h-[6.4vw] colab-card flex flex-col justify-center items-center relative -top-[5.5vw] right-[2.3vw] hero-box-shadow'>
                            <div className='font-[poppins] sm:text-[0.9vw] font-semibold'>Over</div>
                            <div style={{ color: '#FF8002' }} className=' sm:text-[0.8vw] '>1000+ Collaborations</div>
                            <div className='mt-[0.8vw] '>
                                <img src={HeroColab} alt='' className='w-[8vw] h-[1.8vw]' />
                            </div>

                        </div>
                        <div className='bg-black w-[4.7vw] h-[4.3vw] rounded-[14px] flex flex-col justify-center items-center relative -top-[33vw] left-[4vw] hero-box-shadow'>
                            <div className='text-white font-bold font-[poppins] sm:text-[1.1vw]'>100+</div>
                            <div className='text-white font-normal font-[poppins] sm:text-[0.7vw]'>Projects</div>
                        </div>
                        <div className='bg-white w-[4.7vw] h-[4.3vw] rounded-[14px] flex flex-col justify-center items-center relative -top-[19.5vw] left-[21vw] hero-box-shadow'>
                            <div className='text-black font-bold font-[poppins] sm:text-[1.1vw]'>60%</div>
                            <div className='text-black font-normal font-[poppins] sm:text-[0.7vw]'>Projects</div>
                            <img src={redCard} className='absolute -top-[2vw] -right-[2.5vw] w-[4.5vw] h-[4.5vw]' />
                        </div>

                    </div>
                </div>

            </div>

            {isModalOpen && increment < 4 ?
                <Questionaire setModalState={setModalState} questionaireData={questionaireData[increment]} setIncrementValue={setIncrementValue} />
                : <></>
            }
            {!isOpen ? <ScrollToTop /> : <></>}
        </>
    )
}

export default HeroSection